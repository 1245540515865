<!-- src/components/Footer.vue -->
<template>
  <div style="background-color: #f9f6f5">
    <div class="footer">
      <div class="item-box">
        <h3>联系我们</h3>
        <img :src="require(`@/assets/mobile/cdhw-FFFFFF.png`)" alt="one-dl" />
        <p>地 址：北京邮电大学</p>
        <p>邮 箱：1677319677@qq.com</p>
        <p>邮 编：100876</p>
      </div>
      <div class="item-box">
        <h3>关注我们</h3>
        <img :src="require(`@/assets/mobile/cdhw-FFFFFF.png`)" alt="one-dl" />
        <div></div>
        <img :src="qrCodeSrc" alt="one-dl" />
        <p>
          本实验室立足文化资源数据的积淀和价值挖掘，积极融入文化和科技融合国家战略，开展文化计算大脑，文化基因语义模型研究，以守正创新增强中华文明传播力、影响力。
        </p>
      </div>
      <div class="item-box">
        <h3>相关平台</h3>
        <img :src="require(`@/assets/mobile/cdhw-FFFFFF.png`)" alt="one-dl" />
        <div class="item-box-btn">文化计算在线</div>
        <div class="item-box-btn">彣芯数据库</div>
        <div class="item-box-btn">彣脑大模型</div>
      </div>
      <img
        style="width: 70%; margin: 0 auto; display: block"
        :src="require(`@/assets/mobile/footer-dhw.png`)"
        alt="one-dl"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "PageFooter",
  data() {
    return {
      qrCodeSrc: require("@/assets/qrcode.png"), // 确保路径正确
    };
  },
};
</script>

<style scoped>
* {
  font-family: "FZCuJinLJW", sans-serif; /* 设置默认字体 */
  line-height: 30px;
}
.footer {
  background-color: #29140d;
  padding: 40px 0;
  border-radius: 0 150px 0 0;
}
.item-box {
  text-align: center;
  margin-top: 80px;
}
.item-box h3 {
  font-size: 22px;
  margin: 10px;
  color: #ffffff;
}
.item-box p {
  width: 80%;
  text-align: center;
  font-size: 18px;
  margin: 10px auto;
  color: #ffffff;
}
.item-box img {
  width: 180px;
  margin-bottom: 20px;
}
.item-box-btn {
  margin: 0 auto 20px auto;
  font-size: 20px;
  width: 200px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  border-radius: 30px;
  background-color: #ffffff20;
}
</style>
