<!-- src/App.vue -->
<template>
  <div id="app" class="app-container">
    <div v-if="isMobile">
      <MobileHeader />
      <router-view></router-view>
    </div>
    <div v-else>
      <Header />
      <main class="main-content">
        <router-view></router-view>
      </main>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import MobileHeader from "./components/mobile/Header.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    MobileHeader,
  },
  data() {
    return {
      isMobile: false,
      isShowLogo: true,
    };
  },
  mounted() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    this.isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent
    );
    var _this = this;
    if (_this.isMobile) {
      setTimeout(() => {
        var pathdDta = _this.$route.path.split("/")[1];
        if (pathdDta != "mobile") {
          this.$router.push({
            path: "/mobile" + _this.$route.path,
            query: {},
          });
        }
      }, 100);
    } else {
      setTimeout(() => {
        var pathdDta = _this.$route.path.split("/")[1];
        if (pathdDta == "mobile") {
          this.$router.push({
            path: "/" + _this.$route.path.split("/")[2],
            query: {},
          });
        }
      }, 100);
    }
  },
};
</script>

<style>
/* 引入字体 */
@import url('@/assets/fonts.css');
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* 确保容器至少占据整个视口高度 */
}

.main-content {
  flex: 1; /* 主内容区域占据剩余空间 */
  padding: 20px; /* 可以根据需要调整内边距 */
}

/* 其他全局样式 */
</style>
