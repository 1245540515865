<template>
  <div class="main">
    <div class="banner">
      <div class="banner-title">
        <h2>文化计算在线</h2>
        <p>自主研发的文化资源大数据采存算信呈工具集</p>
      </div>
    </div>
    <div class="content">
      <div class="box-1">
        <div class="box-1-title">
          <img :src="require(`@/assets/mobile/gjx-icon.svg`)" alt="dlicon" />
          <p>文化计算在线</p>
        </div>
        <div class="box-1-content">
          <div class="box-1-content-c">
            <p>
              文化资源大数据采存算信传呈工具集，是北京邮电大学“移动媒体与文化计算北京市重点实验室”自主打造的中华传统文化资源数据处理工具平台，旨在应用实验室多年积累的文化计算相关算法，打造一系列生产工具，为文化资源数字化内容实现可量化、可计算、可分析、可展示提供技术支撑，为中华传统文化遗产数字化保护与利用、传统文化内涵揭示及传承机理分析奠定技术基础。
            </p>
          </div>
        </div>
      </div>
      <!-- 纹样提取工具 -->
      <div class="box-item-content">
        <h3>纹样提取工具</h3>
        <img :src="require(`@/assets/mobile/cdhw-tq.png`)" alt="one-dl" />
        <div class="box-item-content-body">
          <img :src="require(`@/assets/page4_lower_gif_1.gif`)" alt="one-dl" />
        </div>
      </div>
      <!-- 线稿提取工具 -->
      <div class="box-item-content">
        <h3>线稿提取工具</h3>
        <img :src="require(`@/assets/mobile/cdhw-xgtq.png`)" alt="one-dl" />
        <div class="box-item-content-body">
          <img :src="require(`@/assets/page4_lower_gif_2.gif`)" alt="one-dl" />
        </div>
      </div>
      <!-- 矢量化工具 -->
      <div class="box-item-content">
        <h3>矢量化工具</h3>
        <img :src="require(`@/assets/mobile/cdhw-slh.png`)" alt="one-dl" />
        <div class="box-item-content-body">
          <img :src="require(`@/assets/page4_lower_gif_3.gif`)" alt="one-dl" />
        </div>
      </div>
      <!-- 色彩提取工具 -->
      <div class="box-item-content">
        <h3>色彩提取工具</h3>
        <img :src="require(`@/assets/mobile/cdhw-sctq.png`)" alt="one-dl" />
        <div class="box-item-content-body">
          <img :src="require(`@/assets/page4_lower_gif_4.gif`)" alt="one-dl" />
        </div>
      </div>
      <!-- 背景清除工具 -->
      <div class="box-item-content">
        <h3>背景清除工具</h3>
        <img :src="require(`@/assets/mobile/cdhw-bjxc.png`)" alt="one-dl" />
        <div class="box-item-content-body">
          <img :src="require(`@/assets/page4_lower_gif_5.gif`)" alt="one-dl" />
        </div>
      </div>
      <!-- 图像超分工具 -->
      <div class="box-item-content">
        <h3>图像超分工具</h3>
        <img :src="require(`@/assets/mobile/cdhw-txcf.png`)" alt="one-dl" />
        <div class="box-item-content-body">
          <img :src="require(`@/assets/page4_lower_gif_6.gif`)" alt="one-dl" />
        </div>
      </div>
      <!-- 页脚 -->
      <MobileFooter />
    </div>
  </div>
</template>

<script>
import MobileFooter from "@/components/mobile/Footer.vue";
export default {
  name: "PageFour",
  components: {
    MobileFooter,
  },
  data() {
    return {
    };
  },
  
};
</script>

<style scoped>
* {
  font-family: "FZCuJinLJW", sans-serif; /* 设置默认字体 */
  line-height: 30px;
}
.main {
  width: 100%;
  height: 100%;
  background-color: #f9f6f5;
  position: relative;
}
.banner {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  background-image: url("@/assets/mobile/PageFourBan.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.banner-title {
  padding-top: 120px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.banner-title h2 {
  font-size: 30px;
  margin: 0 auto;
  padding: 0;
  color: #fff;
  width: 100%;
  text-align: center;
}
.banner-title p {
  font-size: 16px;
  color: #fff;
  line-height: 19px;
  text-align: center;
}
.banner-title img {
  width: 340px;
}
.content {
  width: 100%;
  position: relative;
  z-index: 2;
  padding-top: 270px;
}
.box-1 {
  background-color: #ffffff;
  background-image: url("@/assets/mobile/shui-bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  padding: 30px 30px 80px 30px;
  border-radius: 150px 0 0 0;
}
.box-1-title {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 20px;
}
.box-1-title p {
  font-size: 20px;
  font-weight: bold;
  color: #cfad70;
  padding: 0;
  margin: 0;
}
.box-1-title img {
  width: 24px;
  margin-right: 10px;
}
.box-1-content {
  background-image: url("@/assets/mobile/kapian-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #cfad70;
  margin-top: 30px;
  border-radius: 50px;
}
.box-1-content-c {
  text-align: center;
  padding: 40px;
}
.box-1-content-c img {
  width: 200px;
}
.box-1-content-c h3 {
  font-size: 26px;
  color: #fff;
}
.box-1-content-c p {
  font-size: 17px;
  color: #fff;
}
.box-item-content {
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
  background-color: #f9f6f5;
  background-image: url("@/assets/mobile/shui-bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
}
.box-item-content h3 {
  font-size: 23px;
  margin: 10px;
  color: #29140d;
}
.box-item-content img {
  width: 220px;
}
.box-item-content-body {
  font-size: 18px;
  color: #29140d;
  width: 340px;
  margin: 30px auto 0 auto;
}
.box-item-content-body img {
  width: 100%;
}

.box-item-content-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box-item-content-item-img {
  width: calc(100% / 2.1);
}
.box-item-content-item-img img {
  width: 100%;
}
</style>
