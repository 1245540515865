// src/router/index.js
import { createRouter, createWebHashHistory } from 'vue-router';
import PageOne from '@/views/Page1.vue';
import PageTwo from '@/views/Page2.vue';
import PageThree from '@/views/Page3.vue';
import PageFour from '@/views/Page4.vue';
import PageFive from '@/views/Page5.vue';
import PageSix from '@/views/Page6.vue';
/**
 * 移动设备路由
 */
import MobilePageOne from '@/views/mobile/Page1.vue';
import MobilePageTwo from '@/views/mobile/Page2.vue';
import MobilePageThree from '@/views/mobile/Page3.vue';
import MobilePageFour from '@/views/mobile/Page4.vue';
import MobilePageFive from '@/views/mobile/Page5.vue';
import MobilePageSix from '@/views/mobile/Page6.vue';

const routes = [
  { path: '/page1', name: 'PageOne', component: PageOne },
  { path: '/page2', name: 'PageTwo', component: PageTwo },
  { path: '/page3', name: 'PageThree', component: PageThree },
  { path: '/page4', name: 'PageFour', component: PageFour },
  { path: '/page5', name: 'PageFive', component: PageFive },
  { path: '/page6', name: 'PageSix', component: PageSix },
  { path: '/', redirect: '/page1' }, // 默认重定向到 Page1
  /** 
   * 移动设备路由
   */
  { path: '/mobile/page1', name: 'MobilePageOne', component: MobilePageOne },
  { path: '/mobile/page2', name: 'MobilePageTwo', component: MobilePageTwo },
  { path: '/mobile/page3', name: 'MobilePageThree', component: MobilePageThree },
  { path: '/mobile/page4', name: 'MobilePageFour', component: MobilePageFour },
  { path: '/mobile/page5', name: 'MobilePageFive', component: MobilePageFive },
  { path: '/mobile/page6', name: 'MobilePageSix', component: MobilePageSix },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;