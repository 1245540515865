<template>
  <div class="header">
    <div class="nav">
      <img
        v-show="isShowLogo"
        class="logo"
        :src="require(`@/assets/mobile/logo.svg`)"
        alt="logo"
      />
      <div v-show="!isShowLogo"></div>
      <div class="right">
        <div class="menu" @click="open()">
          <img :src="require(`@/assets/mobile/menu.svg`)" alt="menu" />
        </div>
      </div>
    </div>
    <div class="menu-box" id="child" v-show="isMenu" @mousewheel.prevent>
      <div class="menu-title">
        <h3>菜单</h3>
        <img :src="require(`@/assets/mobile/cdhw.png`)" alt="menu" />
      </div>
      <div class="menu-list">
        <div
          class="menu-item"
          :class="{ menuActive: item.currentShow }"
          v-for="(item, index) in menuList"
          :key="index"
          @click="skip(item)"
        >
          <img
            v-show="item.currentShow"
            :src="require(`@/assets/mobile/chun-logo.svg`)"
            alt="menu"
          />
          <p>{{ item.name }}</p>
        </div>
      </div>
      <div class="menu-close" @click="close()">
        <img :src="require(`@/assets/mobile/close.svg`)" alt="menu" />
      </div>
    </div>
    <div style="height: 70px"></div>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  data() {
    return {
      menuList: [
        { name: "文化计算大脑", currentShow: true, router: "/mobile/page1" },
        { name: "彣芯雕龙计划", currentShow: false, router: "/mobile/page2" },
        { name: "文化记忆符号", currentShow: false, router: "/mobile/page3" },
        { name: "文化计算在线", currentShow: false, router: "/mobile/page4" },
        { name: "文化计算课程", currentShow: false, router: "/mobile/page5" },
        { name: "活化传播应用", currentShow: false, router: "/mobile/page6" },
      ],
      isMenu: false,
      isShowLogo: true,
    };
  },
  mounted() {
    // this.$emit("isMobile", true);
    document.getElementById("child").addEventListener("wheel", function (event) {
      event.stopPropagation(); // 阻止事件冒泡到父元素
    });
  },
  methods: {
    /**
     *  关闭菜单
     */
    close() {
      this.isMenu = false;
      // 启用滚动
      window.onscroll = null;
      document.body.style.overflow = "";
      document.body.style.position = "";
    },
    /**
     *  打开菜单
     */
    open() {
      this.isMenu = true;
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
    },
    /**
     * 跳转
     */
    skip(item) {
      this.$router.push(item.router);
      this.close()
    },
  },
  watch: {
    $route(to) {
      if (to.path == "/mobile/page1") {
        this.isShowLogo = false;
      } else {
        this.isShowLogo = true;
      }
      for (let item of this.menuList) {
        if (item.router === to.path) {
          item.currentShow = true;
        } else {
          item.currentShow = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.header {
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}
.nav {
  width: calc(100% - 30px);
  height: 60px;
  /* background-color: #29140de3; */
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
}
.logo {
  width: 160px;
  transition: 0.3s;
}
.logo:active {
  background-color: #cfad70;
}
.right {
  display: flex;
  align-items: center;
  height: 100%;
}
.menu {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cfad7050;
  border-radius: 14px;
  border: solid 1px #cfad70;
  transition: 0.3s;
}
.menu:active {
  border: solid 1px #cfad7000;
  width: 50px;
}
.menu img {
  width: 24px;
}
.menu-box {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #29140de3;
  z-index: 1000;
}
.menu-title {
  width: 200px;
  margin: 60px auto 0 auto;
}
.menu-title h3 {
  font-size: 30px;
  width: 100%;
  text-align: center;
  color: #cfad70;
  margin: 0 auto;
}
.menu-title img {
  width: 100%;
}
.menu-list {
  width: 190px;
  margin: 0 auto;
}
.menu-item {
  margin-top: 20px;
  width: calc(100% - 30px);
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.2s;
}
.menu-item:active {
  background-color: #cfad7020;
  border-radius: 22px;
  border: solid 1px #cfad70;
}
.menu-item img {
  width: 24px;
}
.menu-item p {
  text-align: center;
  width: 100%;
  font-size: 20px;
  margin: 0 0 0 10px;
  color: #cfad70;
  font-weight: bold;
}
.menuActive {
  background-color: #cfad7020;
  border-radius: 22px;
  border: solid 1px #cfad70;
}
.menu-close {
  height: 50px;
  width: 50px;
  margin: 30px auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cfad7050;
  border-radius: 20px;
  border: solid 1px #cfad70;
  transition: 0.3s;
}
.menu-close:active {
  border: solid 1px #cfad7000;
  width: 100px;
}
.menu-close img {
  width: 30px;
}
</style>
<style>
body {
  margin: 0;
}
</style>
