<template>
  <div class="main">
    <div class="banner">
      <div class="banner-title">
        <h2>文化计算课程</h2>
        <p>赋能智慧解码古韵，AI共绘华章</p>
      </div>
    </div>
    <div class="content">
      <div class="box-1">
        <div class="box-1-title">
          <img :src="require(`@/assets/mobile/page5-wenhuakecheng.svg`)" alt="dlicon" />
          <p>文化计算课程</p>
        </div>
        <div class="box-1-content">
          <div class="box-1-content-c">
            <p>
              文化计算遇到人工智能，必将产生交融与火花，让文化与科技的跨界联名更加透彻，智能化
              技术让更多文化符号、文化基因、文化精髓挖掘出来、传承出去，展现出奇光异彩！
            </p>
          </div>
        </div>
      </div>
      <!-- 课程之美 -->
      <div class="box-item-content">
        <h3>课程之美</h3>
        <img :src="require(`@/assets/mobile/cdhw-CFAD70.png`)" alt="one-dl" />
        <div class="box-item-content-body">
          <div>
            <div class="box-item-content-body-item">
              <div class="box-item-content-body-item-img-1">
                <img
                  :src="require(`@/assets/mobile/page5-wenhuarumen.png`)"
                  alt="one-dl"
                />
              </div>
              <div class="box-item-content-body-item-text-1">
                <h2>文化计算入门</h2>
                <p>
                  文化计算 (Cultural Computing)
                  利用最优化理论、社会计算、大数据、人工智能等技术并与人文、历史等学科相互交叉融合，实现对文化内容挖掘传播、推动数字人文研
                  究，促进文化传承和创新的技术手段。
                </p>
                <h2>主讲人：赵海英</h2>
              </div>
            </div>
            <div class="box-item-content-body-item">
              <div class="box-item-content-body-item-img-1">
                <img
                  :src="require(`@/assets/mobile/page5-tongshirumen.png`)"
                  alt="one-dl"
                />
              </div>
              <div class="box-item-content-body-item-text-1">
                <h2>传统文化通识课入门</h2>
                <p>
                  传统文化通识课入门，是一门引领你探索华夏五千年文明精髓的课程，从诗词歌赋到书法绘画，从古代哲学到民俗风情，深度解读传统文化精髓，培养文化自觉与自信，让智慧与美学点亮你的世界。
                </p>
                <h2>主讲人：熊华</h2>
              </div>
            </div>
            <div class="box-item-content-body-item">
              <div class="box-item-content-body-item-img-1">
                <img
                  :src="require(`@/assets/mobile/page5-wenhuazhilv.png`)"
                  alt="one-dl"
                />
              </div>
              <div class="box-item-content-body-item-text-1">
                <h2>文化之旅</h2>
                <p>
                  文化计算遇见人工智能，是代表着科技实力的人工智能与代表着中华民族文化基因的文化计算完美融合，也让“跨界”这一词有了更大胆的定义，更明确的目标方向。
                </p>
                <h2>主讲人：侯小刚</h2>
              </div>
            </div>
            <div class="box-item-content-body-item">
              <div class="box-item-content-body-item-img-1">
                <img
                  :src="require(`@/assets/mobile/page5-wenhuajiyi.png`)"
                  alt="one-dl"
                />
              </div>
              <div class="box-item-content-body-item-text-1">
                <h2>文化记忆</h2>
                <p>
                  人工智能&文化计算印证中华文化源远流长博大精深，推动人工智能的发展、更具智慧!开展文物纹样、色彩、技法、审美、音乐、舞蹈等历史文化价值挖掘研究，揭示传统文化基因的演化规律，用数字技术讲好中国故事。
                </p>
                <h2>主讲人：侯小刚</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 页脚 -->
      <MobileFooter />
    </div>
  </div>
</template>

<script>
import MobileFooter from "@/components/mobile/Footer.vue";
export default {
  name: "PageFive",
  components: {
    MobileFooter,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
* {
  font-family: "FZCuJinLJW", sans-serif; /* 设置默认字体 */
  line-height: 30px;
}
.main {
  width: 100%;
  height: 100%;
  background-color: #f9f6f5;
  position: relative;
}
.banner {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  background-image: url("@/assets/mobile/PageFiveBan.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.banner-title {
  padding-top: 120px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.banner-title h2 {
  font-size: 30px;
  margin: 0 auto;
  padding: 0;
  color: #fff;
  width: 100%;
  text-align: center;
}
.banner-title p {
  font-size: 16px;
  color: #fff;
  line-height: 19px;
  text-align: center;
}
.banner-title img {
  width: 340px;
}
.content {
  width: 100%;
  position: relative;
  z-index: 2;
  padding-top: 270px;
}
.box-1 {
  background-color: #ffffff;
  background-image: url("@/assets/mobile/shui-bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  padding: 30px 30px 80px 30px;
  border-radius: 150px 0 0 0;
}
.box-1-title {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 20px;
}
.box-1-title p {
  font-size: 26px;
  font-weight: bold;
  color: #5274a8;
  padding: 0;
  margin: 0;
}
.box-1-title img {
  width: 30px;
  margin-right: 10px;
}
.box-1-content {
  background-image: url("@/assets/mobile/kapian-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #5274a8;
  margin-top: 30px;
  border-radius: 50px;
}
.box-1-content-c {
  text-align: center;
  padding: 40px;
}
.box-1-content-c img {
  width: 200px;
}
.box-1-content-c h3 {
  font-size: 26px;
  color: #fff;
}
.box-1-content-c p {
  font-size: 18px;
  color: #fff;
}
.box-item-content {
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
  background-color: #f9f6f5;
  background-image: url("@/assets/mobile/shui-bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
}
.box-item-content h3 {
  font-size: 23px;
  margin: 10px;
  color: #29140d;
}
.box-item-content img {
  width: 220px;
}
.box-item-content-body {
  font-size: 18px;
  color: #29140d;
  width: 340px;
  margin: 30px auto 0 auto;
}
.box-item-content-body img {
  width: 100%;
}

.box-item-content-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box-item-content-item-img {
  width: calc(100% / 2.1);
}
.box-item-content-item-img img {
  width: 100%;
}
.box-item-content-body-item {
  display: flex;
  align-items: flex-start;
  background-image: url("@/assets/mobile/kapian-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #cfad70;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
}
.box-item-content-body-item-img-1 {
  width: 80px;
}
.box-item-content-body-item-img-1 img {
  width: 100%;
  display: block;
}
.box-item-content-body-item-text-1 {
  width: calc(100% - 90px);
  margin-left: 10px;
  text-align: left;
}
.box-item-content-body-item-text-1 h2 {
  margin: 0 0 10px 0;
  color: #fff;
  font-size: 20px;
  line-height: 22px;
}
.box-item-content-body-item-text-1 p {
  margin: 0 0 6px 0;
  padding: 0;
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  word-break: break-all;
  text-align: justify;
  text-justify: auto;
}
</style>
