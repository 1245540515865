<template>
  <div class="main">
    <div class="banner">
      <div class="banner-title">
        <img :src="require(`@/assets/mobile/ban-logo.svg`)" alt="logo" />
        <p>赋能中华传统文化基因解码和活化应用</p>
      </div>
    </div>
    <div class="content">
      <div class="box-1">
        <div class="box-1-title">
          <img :src="require(`@/assets/mobile/dl-icon.png`)" alt="dlicon" />
          <p>彣芯雕龙计划</p>
        </div>
        <div class="box-1-content">
          <div class="box-1-content-c">
            <img :src="require(`@/assets/mobile/one-dl.png`)" alt="one-dl" />
            <h3>彣芯雕龙计划</h3>
            <p style="text-align: left;">
             彣芯雕龙计划源于对文化遗产保护利用所面临难题和挑战问题整合，探索新的解决问题模式，打破学科间的语言壁垒，促进学科间的深度融合，构建文化和科技交叉型人才培育的新型平台。
            </p>
          </div>
        </div>
      </div>
      <!-- 中华传统文化记忆符号库 -->
      <div class="box-item-content">
        <h3>中华传统文化记忆符号库</h3>
        <img :src="require(`@/assets/mobile/cdhw-29140D.png`)" alt="one-dl" />
        <div class="box-item-content-img one-fuhao"></div>
        <div class="box-item-content-body">
          中华传统文化记忆符号库是一个综合性的文化资源库，它涵盖了纹样库、颜色库、工法库和型制库等多个方面。
        </div>
      </div>
      <!-- 文化资源大数据·采存算信传呈 -->
      <div class="box-item-content" style="background-color: #fff">
        <h3 style="color: #cfad70">文化资源大数据·采存算信传呈</h3>
        <img :src="require(`@/assets/mobile/cdhw-CFAD70.png`)" alt="one-dl" />
        <div class="box-item-content-img one-cj" style="background-color: #cfad70"></div>
        <div class="box-item-content-body" style="color: #cfad70">
          旨在应用实验室多年积累的文化计算相关算法，打造一系列生产工具，为文化资源数字化内容实现可量化、可计算、可分析、可展示提供技术支撑，为中华传统文化遗产数字化保护与利用、传统文化内涵揭示及传承机理分析奠定技术基础。
        </div>
      </div>
      <!-- 文化计算课程体系 -->
      <div class="box-item-content">
        <h3 style="color: #B43D20">文化计算课程体系</h3>
        <img :src="require(`@/assets/mobile/cdhw-B43D20.png`)" alt="one-dl" />
        <div class="box-item-content-img one-kc" style="background-color: #B43D20"></div>
        <div class="box-item-content-body" style="color: #B43D20">
          文化计算遇到人工智能，让文化与科技的跨界联名更加透彻，智能化技术让更多文化符号、文化基因、文化精髓挖掘出来、传承出去，展现出奇光异彩。
        </div>
      </div>
      <!-- 文化计算课程体系 -->
      <div class="box-item-content">
         <img style="width: 300px;" :src="require(`@/assets/mobile/one-yy.png`)" alt="one-dl" />
      </div>
      <!-- 页脚 -->
      <MobileFooter/>
    </div>
  </div>
</template>

<script>
import MobileFooter from "@/components/mobile/Footer.vue";
export default {
  name: "PageOne",
  components: {
    MobileFooter,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
* {
  font-family: "FZCuJinLJW", sans-serif; /* 设置默认字体 */
  line-height: 30px;
}
.main {
  width: 100%;
  height: 100%;
  background-color: #f9f6f5;
  position: relative;
}
.banner {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  background-image: url("@/assets/mobile/PageOneBan.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.banner-title {
  padding-top: 120px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.banner-title p {
  font-size: 18px;
  color: #fff;
}
.banner-title img {
  width: 260px;
}
.content {
  width: 100%;
  position: relative;
  z-index: 2;
  padding-top: 270px;
}
.box-1 {
  background-color: #ffffff;
  background-image: url("@/assets/mobile/shui-bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  padding: 30px 30px 80px 30px;
  border-radius: 150px 0 0 0;
}
.box-1-title {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 20px;
}
.box-1-title p {
  font-size: 26px;
  font-weight: bold;
  color: #29514d;
  padding: 0;
  margin: 0;
}
.box-1-title img {
  width: 30px;
  margin-right: 10px;
}
.box-1-content {
  background-image: url("@/assets/mobile/kapian-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #29514d;
  margin-top: 30px;
  border-radius: 50px;
}
.box-1-content-c {
  text-align: center;
  padding: 40px;
}
.box-1-content-c img {
  width: 160px;
}
.box-1-content-c h3 {
  font-size: 26px;
  color: #fff;
}
.box-1-content-c p {
  font-size: 18px;
  color: #fff;
}
.box-item-content {
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
  background-color: #f9f6f5;
  background-image: url("@/assets/mobile/shui-bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
}
.box-item-content h3 {
  font-size: 23px;
  margin: 10px;
  color: #29140d;
}
.box-item-content img {
  width: 220px;
}
.box-item-content-img {
  width: 340px;
  height: 180px;
  border-radius: 80px;
  background-color: #29140d;
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 20px auto 30px auto;
}
.box-item-content-body {
  font-size: 18px;
  color: #29140d;
  width: 340px;
  margin: 0 auto;
}

/* //背景图片 */
.one-fuhao{
  background-image: url("@/assets/mobile/one-fuhao.png");
}
.one-cj{
    background-image: url("@/assets/mobile/one-cj.png");
}
.one-kc{
    background-image: url("@/assets/mobile/one-kc.png");
}
</style>
