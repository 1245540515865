<template>
  <div class="main">
    <div class="banner">
      <div class="banner-title">
        <h2>活化传播应用</h2>
        <p>走进文化之源，领略文化计算之美</p>
      </div>
    </div>
    <div class="content">
      <div class="box-1">
        <div class="box-1-title">
          <img :src="require(`@/assets/mobile/page6-wenhuakecheng.svg`)" alt="dlicon" />
          <p>解码利用与国际传播</p>
        </div>
        <div class="box-1-content">
          <div class="box-1-content-c">
            <p>
              中华传统文化的“活化利用”旨在通过创新方式让传统文化焕发新生。2023年，推出了中华文明云展，让观众足不出户领略文物风采，2024年，打造了“中华纹样五千年”沉浸展，传承精美纹样文化，举办了泰国展、中阿展和欧洲巡展，促进文化交流互鉴，让中国传统文化更加鲜活、生动，增强了文化自信。
            </p>
          </div>
        </div>
      </div>
      <!-- 活化利用案例 -->
      <div class="box-item-content">
        <h3>活化利用案例</h3>
        <img :src="require(`@/assets/mobile/cdhw-CFAD70.png`)" alt="one-dl" />
        <div class="box-item-content-body">
          <div>
            <div class="box-item-content-body-item-img">
              <img :src="require(`@/assets/page6_lower_image_1.png`)" alt="one-dl" />
              <h2>看见中国——国际数字文化展</h2>
            </div>
            <div class="box-item-content-body-item-img">
              <img :src="require(`@/assets/page6_lower_image_2.png`)" alt="one-dl" />
              <h2>中华文明云展</h2>
            </div>
            <div class="box-item-content-body-item-img">
              <img :src="require(`@/assets/page6_lower_image_3.png`)" alt="one-dl" />
              <h2>中华纹样五千年</h2>
            </div>
            <div class="box-item-content-body-item-img">
              <img :src="require(`@/assets/page6_lower_image_4.png`)" alt="one-dl" />
              <h2>齐鲁文化基因解码</h2>
            </div>
          </div>
        </div>
      </div>
      <!-- 页脚 -->
      <MobileFooter />
    </div>
  </div>
</template>

<script>
import MobileFooter from "@/components/mobile/Footer.vue";
export default {
  name: "PageSix",
  components: {
    MobileFooter,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
* {
  font-family: "FZCuJinLJW", sans-serif; /* 设置默认字体 */
  line-height: 30px;
}
.main {
  width: 100%;
  height: 100%;
  background-color: #f9f6f5;
  position: relative;
}
.banner {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  background-image: url("@/assets/mobile/PageSixBan.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.banner-title {
  padding-top: 120px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.banner-title h2 {
  font-size: 30px;
  margin: 0 auto;
  padding: 0;
  color: #fff;
  width: 100%;
  text-align: center;
}
.banner-title p {
  font-size: 16px;
  color: #fff;
  line-height: 19px;
  text-align: center;
}
.banner-title img {
  width: 340px;
}
.content {
  width: 100%;
  position: relative;
  z-index: 2;
  padding-top: 270px;
}
.box-1 {
  background-color: #ffffff;
  background-image: url("@/assets/mobile/shui-bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  padding: 30px 30px 80px 30px;
  border-radius: 150px 0 0 0;
}
.box-1-title {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 20px;
}
.box-1-title p {
  font-size: 22px;
  font-weight: bold;
  color: #5274a8;
  padding: 0;
  margin: 0;
}
.box-1-title img {
  width: 30px;
  margin-right: 10px;
}
.box-1-content {
  background-image: url("@/assets/mobile/kapian-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #5274a8;
  margin-top: 30px;
  border-radius: 50px;
}
.box-1-content-c {
  text-align: center;
  padding: 40px;
}
.box-1-content-c img {
  width: 200px;
}
.box-1-content-c h3 {
  font-size: 26px;
  color: #fff;
}
.box-1-content-c p {
  font-size: 18px;
  color: #fff;
}
.box-item-content {
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
  background-color: #f9f6f5;
  background-image: url("@/assets/mobile/shui-bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
}
.box-item-content h3 {
  font-size: 23px;
  margin: 10px;
  color: #29140d;
}
.box-item-content img {
  width: 220px;
}
.box-item-content-body {
  font-size: 18px;
  color: #29140d;
  width: 340px;
  margin: 30px auto 0 auto;
}
.box-item-content-body img {
  width: 100%;
}

.box-item-content-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box-item-content-item-img {
  width: calc(100% / 2.1);
}
.box-item-content-item-img img {
  width: 100%;
}
.box-item-content-body-item {
  display: flex;
  align-items: flex-start;
  background-image: url("@/assets/mobile/kapian-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #cfad70;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
}
.box-item-content-body-item-img{
    margin-bottom: 20px;
}
.box-item-content-body-item-img img {
  width: 100%;
  display: block;
  border-radius: 10px;
  overflow: hidden;
  
}
.box-item-content-body-item-img h2 {
  margin: 10px 0 10px 0;
  color: #29140D;
  font-size: 20px;
  line-height: 22px;
}
.box-item-content-body-item-text-1 p {
  margin: 0 0 6px 0;
  padding: 0;
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  word-break: break-all;
  text-align: justify;
  text-justify: auto;
}
</style>
