<template>
  <div class="main">
    <div class="banner">
      <div class="banner-title">
        <h2>中华传统文化记忆符号库</h2>
        <p>
          纹样、色彩、工法、形制
        </p>
      </div>
    </div>
    <div class="content">
      <div class="box-1">
        <div class="box-1-title">
          <p>中华传统文化记忆符号库</p>
        </div>
        <div class="box-1-content">
          <div class="box-1-content-c">
            <p>
              中华传统文化记忆符号库是一个综合性的文化资源库，它涵盖了纹样库、颜色库、工法库和型制库等多个方面。该库致力于解码中华传统文化中的记忆符号，通过精心搜集和整理古代器物、建筑、服饰等领域的经典元素，追溯并传承华夏文明的独特美学。无论是繁复精美的纹样，还是典雅古朴的颜色搭配，或是精湛细腻的工艺技法，以及端庄大气的形制设计，都在库中得到了完美的呈现，为后人提供了学习和借鉴的宝贵资源。
            </p>
          </div>
        </div>
      </div>
      <!-- 符号库 -->
      <div class="box-item-content">
        <h3>符号库</h3>
        <img :src="require(`@/assets/mobile/cdhw-29140D.png`)" alt="one-dl" />
        <div class="box-item-content-body">
          <div class="box-item-content-item">
            <div class="box-item-content-item-img">
                <img :src="require(`@/assets/mobile/page3-wenyangku.png`)" alt="one-dl" />
            </div>
            <div class="box-item-content-item-img">
                <img :src="require(`@/assets/mobile/page3-secaiku.png`)" alt="one-dl" />
            </div>
          </div>
          <div class="box-item-content-item">
            <div class="box-item-content-item-img">
                <img :src="require(`@/assets/mobile/page3-gongfaku.png`)" alt="one-dl" />
            </div>
            <div class="box-item-content-item-img">
                <img :src="require(`@/assets/mobile/page3-yuanxingku.png`)" alt="one-dl" />
            </div>
          </div>
        </div>
      </div>
      <!-- 页脚 -->
      <MobileFooter />
    </div>
  </div>
</template>

<script>
import MobileFooter from "@/components/mobile/Footer.vue";
export default {
  name: "PageThree",
  components: {
    MobileFooter,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
* {
  font-family: "FZCuJinLJW", sans-serif; /* 设置默认字体 */
  line-height: 30px;
}
.main {
  width: 100%;
  height: 100%;
  background-color: #f9f6f5;
  position: relative;
}
.banner {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  background-image: url("@/assets/mobile/PageThreeBan.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.banner-title {
  padding-top: 120px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.banner-title h2 {
  font-size: 30px;
  margin: 0 auto;
  padding: 0;
  color: #fff;
  width: 100%;
  text-align: center;
}
.banner-title p {
  font-size: 18px;
  color: #fff;
}
.banner-title img {
  width: 340px;
}
.content {
  width: 100%;
  position: relative;
  z-index: 2;
  padding-top: 270px;
}
.box-1 {
  background-color: #ffffff;
  background-image: url("@/assets/mobile/shui-bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  padding: 30px 30px 80px 30px;
  border-radius: 150px 0 0 0;
}
.box-1-title {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 20px;
}
.box-1-title p {
  font-size: 26px;
  font-weight: bold;
  color: #420202;
  padding: 0;
  margin: 0;
}
.box-1-title img {
  width: 30px;
  margin-right: 10px;
}
.box-1-content {
  background-image: url("@/assets/mobile/kapian-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #420202;
  margin-top: 30px;
  border-radius: 50px;
}
.box-1-content-c {
  text-align: center;
  padding: 40px;
}
.box-1-content-c img {
  width: 200px;
}
.box-1-content-c h3 {
  font-size: 26px;
  color: #fff;
}
.box-1-content-c p {
  font-size: 18px;
  color: #fff;
}
.box-item-content {
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
  background-color: #f9f6f5;
  background-image: url("@/assets/mobile/shui-bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
}
.box-item-content h3 {
  font-size: 23px;
  margin: 10px;
  color: #29140d;
}
.box-item-content img {
  width: 220px;
}
.box-item-content-body {
  font-size: 18px;
  color: #29140d;
  width: 340px;
  margin: 30px auto 0 auto;
}

.box-item-content-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.box-item-content-item-img{
    width: calc(100% / 2.1);
}
.box-item-content-item-img img{
    width: 100%;
}
</style>
