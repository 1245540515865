<template>
  <div class="main">
    <div class="banner">
      <div class="banner-title">
        <!-- <img :src="require(`@/assets/mobile/ban-logo.svg`)" alt="logo" /> -->
        <h2>彣芯雕龙计划</h2>
        <p>智慧解码古韵，AI共绘华章</p>
      </div>
    </div>
    <div class="content">
      <div class="box-1">
        <div class="box-1-title">
          <img :src="require(`@/assets/mobile/dl-icon.png`)" alt="dlicon" />
          <p>什么是彣芯雕龙计划？</p>
        </div>
        <div class="box-1-content">
          <div class="box-1-content-c">
            <img :src="require(`@/assets/mobile/dl-icon.png`)" alt="one-dl" />
            <h3>彣芯雕龙计划</h3>
            <p style="text-align: left;padding-bottom: 0px;margin-bottom: 0px;">
             彣芯雕龙计划源于对文化遗产保护利用所面临难题和挑战问题整合，探索新的解决问题模式，打破学科间的语言壁垒，促进学科间的深度融合，构建文化和科技交叉型人才培育的新型平台。
            </p>
            <p style="text-align: left;padding-top: 0px;margin-top: 10px;">
              文物是不可再生的文化资源，要让文物资源活起来，需要加强文物保护和传承的科学研究，通过“彣芯雕龙计划”部署实施“历史文化遗产保护利用科技创新”研究任务。加强跨部门联合攻关，加强文博单位与高校合作，协同解决文物保护和利用关键技术和重点问题，推动科技成果转化。
            </p>
          </div>
        </div>
      </div>
      <!-- 定位与目标 -->
      <div class="box-item-content">
        <h3>定位与目标</h3>
        <img :src="require(`@/assets/mobile/cdhw-29140D.png`)" alt="one-dl" />
        <div class="box-item-content-img">
          <img :src="require(`@/assets/mobile/wentimoshi.svg`)" alt="one-dl" />
          <p>
            链接更多文物保护实际应用场景和科学技术问题，探索解决文化遗产数字化保护利用问题的共创模式，解决文物保护和利用中的问题。
          </p>
        </div>
        <div class="box-item-content-img">
          <img :src="require(`@/assets/mobile/rencaipeiyang.svg`)" alt="one-dl" />
          <p>
            以问题驱动孕育交叉创新，以大模型、人工智能为引领，培养一批具备更强的解决问题、分析问题，能够解决多学科交叉问题的专业人才。
          </p>
        </div>
      </div>
      <!-- 如何参与 -->
      <div class="box-item-content" style="background-color: #fff">
        <h3>如何参与</h3>
        <img :src="require(`@/assets/mobile/cdhw-29140D.png`)" alt="one-dl" />
        <div class="box-item-content-time">
          <el-timeline style="max-width: 600px">
            <el-timeline-item
              hide-timestamp="false"
              :icon="
                () => {
                  return '1';
                }
              "
              placement="top"
            >
              <!-- 自定义timestamp -->
              <div class="timeline-box">
                <img
                  :src="require(`@/assets/mobile/timeline-shenqing.svg`)"
                  alt="one-dl"
                />
                <p>申请彣芯雕龙课题</p>
              </div>
            </el-timeline-item>
            <el-timeline-item
              hide-timestamp="false"
              :icon="
                () => {
                  return '2';
                }
              "
              placement="top"
            >
              <!-- 自定义timestamp -->
              <div class="timeline-box">
                <img :src="require(`@/assets/mobile/timeline-shenhe.svg`)" alt="one-dl" />
                <p>审核</p>
              </div>
            </el-timeline-item>
            <el-timeline-item
              hide-timestamp="false"
              :icon="
                () => {
                  return '3';
                }
              "
              placement="top"
            >
              <!-- 自定义timestamp -->
              <div class="timeline-box">
                <img :src="require(`@/assets/mobile/timeline-shenhe.svg`)" alt="one-dl" />
                <p>
                  正式开始课题<br />
                  获得导师指导和课程资源
                </p>
              </div>
            </el-timeline-item>
            <el-timeline-item
              hide-timestamp="false"
              :icon="
                () => {
                  return '4';
                }
              "
              placement="top"
            >
              <!-- 自定义timestamp -->
              <div class="timeline-box">
                <img :src="require(`@/assets/mobile/timeline-huibao.svg`)" alt="one-dl" />
                <p>课题汇报验收</p>
              </div>
            </el-timeline-item>
            <el-timeline-item
              hide-timestamp="false"
              :icon="
                () => {
                  return '5';
                }
              "
              placement="top"
            >
              <!-- 自定义timestamp -->
              <div class="timeline-box">
                <img
                  :src="require(`@/assets/mobile/timeline-chengguo.svg`)"
                  alt="one-dl"
                />
                <p>成果发布</p>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <!-- 专家和导师团队 -->
      <div class="box-item-content">
        <h3>专家和导师团队</h3>
        <img :src="require(`@/assets/mobile/cdhw-29140D.png`)" alt="one-dl" />
        <div class="box-item-content-body">
          <div class="box-item-content-body-item">
            <div class="box-item-content-body-item-img">
              <img :src="require(`@/assets/page2_lower_image_4_1.png`)" alt="one-dl" />
            </div>
            <div class="box-item-content-body-item-text">
              <h2>彭群生</h2>
              <p>浙江大学教授</p>
              <p>浙江大学CAD&CG国家重点实验室教授<br />致力于图形学交叉学科的建设</p>
            </div>
          </div>
          <div class="box-item-content-body-item">
            <div class="box-item-content-body-item-img">
              <img :src="require(`@/assets/page2_lower_image_4_2.png`)" alt="one-dl" />
            </div>
            <div class="box-item-content-body-item-text">
              <h2>于振明</h2>
              <p>北京邮电大学副教授</p>
              <p>致力于高速光通信系统、 计算成像与深度学习、智能通信研究</p>
            </div>
          </div>
          <div class="box-item-content-body-item">
            <div class="box-item-content-body-item-img">
              <img :src="require(`@/assets/page2_lower_image_4_3.png`)" alt="one-dl" />
            </div>
            <div class="box-item-content-body-item-text">
              <h2>祁庆国</h2>
              <p>北京博物馆学会副理事长</p>
              <p>主要从事博物馆学、博物馆信息学、文物影像学等领域研究</p>
            </div>
          </div>
          <div class="box-item-content-body-item">
            <div class="box-item-content-body-item-img">
              <img :src="require(`@/assets/page2_lower_image_4_4.png`)" alt="one-dl" />
            </div>
            <div class="box-item-content-body-item-text">
              <h2>马严</h2>
              <p>北京邮电大学教授</p>
              <p>致力于网络管理、网络安全技术、移动IP、IPv6等技术及其应用</p>
            </div>
          </div>
          <div class="box-item-content-body-item">
            <div class="box-item-content-body-item-img">
              <img :src="require(`@/assets/page2_lower_image_4_5.png`)" alt="one-dl" />
            </div>
            <div class="box-item-content-body-item-text">
              <h2>周月</h2>
              <p>北京邮电大学副教授</p>
              <p>致力于文化大数据标准体系、 原真数字化采集、智能图像处理等领域</p>
            </div>
          </div>
          <div class="box-item-content-body-item">
            <div class="box-item-content-body-item-img">
              <img :src="require(`@/assets/page2_lower_image_4_6.png`)" alt="one-dl" />
            </div>
            <div class="box-item-content-body-item-text">
              <h2>侯小刚</h2>
              <p>博士后</p>
              <p>研究方向为图像智能处理及多模态AI 在文化遗产数据价值挖掘和解码利用</p>
            </div>
          </div>
          <div class="box-item-content-body-item">
            <div class="box-item-content-body-item-img">
              <img :src="require(`@/assets/page2_lower_image_4_7.png`)" alt="one-dl" />
            </div>
            <div class="box-item-content-body-item-text">
              <h2>谢伟强</h2>
              <p>领域专家</p>
              <p>致力于情报数据分析及人工智能工程领域的深度实践与创新应用</p>
            </div>
          </div>
          <div class="box-item-content-body-item">
            <div class="box-item-content-body-item-img">
              <img :src="require(`@/assets/page2_lower_image_4_8.png`)" alt="one-dl" />
            </div>
            <div class="box-item-content-body-item-text">
              <h2>熊华</h2>
              <p>领域专家</p>
              <p>主要从事智能图像技术研究及 在教育、文化、工业等领域的应用</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 彣芯雕龙课题 -->
      <div class="box-item-content" style="background-color: #ffffff">
        <h3>彣芯雕龙课题</h3>
        <img :src="require(`@/assets/mobile/cdhw-29140D.png`)" alt="one-dl" />
        <div class="box-item-content-body">
          <div class="box-item-content-body-item">
            <div class="box-item-content-body-item-img-1">
              <img :src="require(`@/assets/mobile/page2-taoci.png`)" alt="one-dl" />
            </div>
            <div class="box-item-content-body-item-text-1">
              <p>
                1.陶器修复工作中的数字虚拟陶片缀合<br />
                2.文物分型分式之造像的智能识别<br />
                3.草书的智能识别<br />
                4.克孜尔石窟壁画的乐器数字复原模拟<br />
                5.孟子及孟氏后裔画像数字化采集
              </p>
            </div>
          </div>
          <div class="hx"></div>
          <div class="box-item-content-body-item">
            <div class="box-item-content-body-item-img-1">
              <img :src="require(`@/assets/mobile/page2-fuyuan.png`)" alt="one-dl" />
            </div>
            <div class="box-item-content-body-item-text-1">
              <p>
                6.北京孔庙石碑文字数字复原<br />
                7.孔庙祭孔大典的虚拟场景复现<br />
                8.特定金文字形的年代谱系构建<br />
                9.基于篆刻字典的篆刻字体识别<br />
                10.孔子圣迹图虚拟动态复现
              </p>
            </div>
          </div>
          <div class="hx"></div>
          <div class="box-item-content-body-item">
            <div class="box-item-content-body-item-img-1">
              <img :src="require(`@/assets/mobile/page2-qp.png`)" alt="one-dl" />
            </div>
            <div class="box-item-content-body-item-text-1">
              <p style="font-size: 13px">
                11.古琴谱中“手势文字”（减字谱）识别<br />
                12.基于绘画残片的佛像线稿结构复原<br />
                13.智能绘制盘碗类器物纹饰展开图<br />
                14.手写契约的智能识别<br />
                15.窑炉平面展开图的数字三维复原
              </p>
            </div>
          </div>
          <div class="hx"></div>
          <div class="box-item-content-body-item">
            <div class="box-item-content-body-item-img-1">
              <img :src="require(`@/assets/mobile/page2-zhineng.png`)" alt="one-dl" />
            </div>
            <div class="box-item-content-body-item-text-1">
              <p>
                16.智能绘制瓶罐类器物纹饰展开图<br />
                17.克孜尔石窟壁画的色彩复原<br />
                18.水族水书中的象形文字识别<br />
                19.石刻浅浮雕纹饰提取<br />
                20.甲骨文字形识别
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- 页脚 -->
      <MobileFooter />
    </div>
  </div>
</template>

<script>
import MobileFooter from "@/components/mobile/Footer.vue";
export default {
  name: "PageTwo",
  components: {
    MobileFooter,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
* {
  font-family: "FZCuJinLJW", sans-serif; /* 设置默认字体 */
  line-height: 30px;
}
.main {
  width: 100%;
  height: 100%;
  background-color: #f9f6f5;
  position: relative;
}
.banner {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  background-image: url("@/assets/mobile/PageTwoBan.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.banner-title {
  padding-top: 120px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.banner-title h2 {
  font-size: 30px;
  margin: 0 auto;
  padding: 0;
  color: #fff;
  width: 100%;
  text-align: center;
}
.banner-title p {
  font-size: 18px;
  color: #fff;
  width: 100%;
  text-align: center;
}
.banner-title img {
  width: 340px;
}
.content {
  width: 100%;
  position: relative;
  z-index: 2;
  padding-top: 270px;
}
.box-1 {
  background-color: #ffffff;
  background-image: url("@/assets/mobile/shui-bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  padding: 30px 30px 80px 30px;
  border-radius: 150px 0 0 0;
}
.box-1-title {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 20px;
}
.box-1-title p {
  font-size: 24px;
  font-weight: bold;
  color: #29514d;
  padding: 0;
  margin: 0;
}
.box-1-title img {
  width: 30px;
  margin-right: 10px;
}
.box-1-content {
  background-image: url("@/assets/mobile/kapian-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #29514d;
  margin-top: 30px;
  border-radius: 50px;
}
.box-1-content-c {
  text-align: center;
  padding: 40px;
}
.box-1-content-c img {
  width: 200px;
}
.box-1-content-c h3 {
  font-size: 26px;
  color: #fff;
}
.box-1-content-c p {
  font-size: 18px;
  color: #fff;
}
.box-item-content {
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
  background-color: #f9f6f5;
  background-image: url("@/assets/mobile/shui-bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
}
.box-item-content h3 {
  font-size: 23px;
  margin: 10px;
  color: #29140d;
}
.box-item-content img {
  width: 220px;
}
.box-item-content-img {
  width: 340px;
  padding: 30px 0;
  /* height: 180px; */
  border-radius: 80px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 20px auto 30px auto;
}
.box-item-content-img img {
  width: 60px;
}
.box-item-content-img p {
  padding: 0 20px;
}
.box-item-content-body {
  font-size: 18px;
  color: #29140d;
  width: 340px;
  margin: 0 auto;
}
.one-cj {
  background-image: url("@/assets/mobile/one-cj.png");
}
.one-kc {
  background-image: url("@/assets/mobile/one-kc.png");
}
.box-item-content-time {
  width: 300px;
  margin: 30px auto 0 auto;
}
:deep(.el-timeline-item__node--normal) {
  height: 30px;
  left: -10px;
  width: 30px;
}
:deep(.el-timeline-item__node) {
  background-color: #fff;
  border: 3px solid #29140d;
}
:deep(.el-timeline-item .el-timeline-item__icon) {
  color: #29140d;
  font-size: 18px;
}
.timeline-box {
  text-align: left;
  padding-left: 50px;
}
.timeline-box img {
  width: 50px;
}
.timeline-box p {
  padding: 0;
  margin: -2px 0 0 0;
  color: #29140d;
  font-size: 16px;
  line-height: 22px;
}
.box-item-content-body-item {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.box-item-content-body-item-img {
  width: 120px;
}

.box-item-content-body-item-img img {
  width: 100%;
  display: block;
}
.box-item-content-body-item-text {
  width: calc(100% - 130px);
  margin-left: 10px;
  text-align: left;
}
.box-item-content-body-item-img-1 {
  width: 80px;
}
.box-item-content-body-item-img-1 img {
  width: 100%;
  display: block;
}
.box-item-content-body-item-text-1 {
  width: calc(100% - 90px);
  margin-left: 10px;
  text-align: left;
}
.box-item-content-body-item-text-1 p {
  margin: 0 0 6px 0;
  padding: 0;
  color: #29140d;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  word-break: break-all;
  text-align: justify;
  text-justify: auto;
}
.box-item-content-body-item-text h2 {
  margin: 0 0 10px 0;
  color: #29140d;
  font-size: 20px;
  line-height: 22px;
}
.box-item-content-body-item-text p {
  margin: 0 0 6px 0;
  padding: 0;
  color: #29140d70;
  font-size: 16px;
  line-height: 22px;
}
.hx {
  background-color: #29140c30;
  height: 1px;
  width: 100%;
  margin: 15px 0;
}
</style>
